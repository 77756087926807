import { BlockCategory, BlockType } from 'App/Lib/Blockly/types';
import { ToolboxDefinition } from 'blockly/core/utils/toolbox';

const toolbox: string | ToolboxDefinition | Element = {
  kind: 'categoryToolbox',
  contents: [
    {
      kind: 'category',
      name: BlockCategory.LABEL,
      icons: {},
      contents: [
        {
          kind: 'block',
          type: BlockType.COMMENT,
        },
        {
          kind: 'block',
          type: BlockType.TITLE,
        },
        {
          kind: 'block',
          type: BlockType.WAIT,
        },
        {
          kind: 'block',
          type: BlockType.REPEAT,
        },
        {
          kind: 'block',
          type: BlockType.IDLE_FOREVER,
        },
        {
          kind: 'block',
          type: BlockType.DIGITAL_WRITE,
        },
        {
          kind: 'block',
          type: BlockType.ANALOG_WRITE,
        },
        {
          kind: 'block',
          type: BlockType.INFRARED_READ,
        },
        {
          kind: 'block',
          type: BlockType.INFRARED_SETUP,
        },
        {
          kind: 'block',
          type: BlockType.NUMBER,
        },
      ],
    },
  ],
};

export default toolbox;
